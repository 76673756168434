<template>
  <section :id="`section-${section.id}`" class="section-default section-default--overview">
    <SubHeadline v-if="section.attributes.values.title" :tag="`h${headlineOffset}`">
      {{ section.attributes.values.title }}
    </SubHeadline>
    <LeadText v-if="section.attributes.values.leadText" class="article-leadtext">
      {{ section.attributes.values.leadText }}
    </LeadText>
    <div v-if="section.attributes.values.backgroundImage" class="background">
      <SpunqImage
        v-for="(image, index) in section.attributes.images.slice(0, 1)"
        :key="index"
        :image="image"
        :width="330"
        :height="600"
        mode="crop"
      />
    </div>
    <div v-if="section.attributes.images.length > 0" class="visual">
      <div v-if="!section.attributes.values.backgroundImage">
        <CaptionImage
          v-for="(image, index) in section.attributes.images.slice(0, 1)"
          :key="index"
          :image="image"
          :width="980"
          :height="540"
          :mobile-width="375"
          :mobile-height="320"
          lazy
        />
      </div>
      <div v-else>
        <!-- if backgroundImage is set take the first image as background and the second as inline-image -->
        <CaptionImage
          v-for="(image, index) in section.attributes.images.slice(1, 2)"
          :key="index"
          :image="image"
          :width="980"
          :height="540"
          :mobile-width="375"
          :mobile-height="320"
          lazy
        />
      </div>
    </div>
    <div>
      <div v-if="section.attributes.values.embedLink" class="emdbedlink embedLink--overview">
        <!-- eslint-disable vue/no-v-html -->
        <div class="responsive embedded-iframe" v-html="embeddedLink" />
        <!-- eslint-enable vue/no-v-html -->
      </div>
      <Spunq
        v-if="section.attributes.values.bodyText"
        :text="section.attributes.values.bodyText"
        :inline-objects="section.relationships.inlineObjects"
        :inline-images="section.attributes.inlineImages"
        :headline-offset="headlineOffset"
        class="section-default__bodytext"
      />
    </div>
    <div v-if="section.relationships.files" class="section-default__files">
      <FileDownload
        v-for="file in section.relationships.files"
        :id="file.attributes.fileId"
        :key="file.id"
        :type="file.attributes.mimeType"
        :title="file.attributes.title"
      />
    </div>
    <slot />
  </section>
</template>

<script>
import SpunqImage from '../../ui/image/spunq-image'
import SubHeadline from '@/components/ui/text/subheadline'
import LeadText from '@/components/ui/text/lead-text'
import Spunq from '@/components/spunq-tags/spunq'
import CaptionImage from '@/components/ui/image/caption-image'
import FileDownload from '@/components/ui/file/file-download'

export default {
  components: {
    SubHeadline,
    LeadText,
    Spunq,
    SpunqImage,
    CaptionImage,
    FileDownload,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    headlineOffset() {
      if (this.section.attributes.level === 0) {
        return this.section.attributes.level + 2
      } else {
        return this.section.attributes.level + 1
      }
    },
    embeddedLink() {
      if (this.section.attributes.values.embedLink !== 'undefined') {
        return this.section.attributes.values.embedLink
      }
      return null
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

// .section-default {
//   &#{&}--overview {
//     display: flex;
//     flex-direction: column;;
//     justify-content: center;
//     padding: 0 15px;
//     &__bla {
//       color: red; // compiles to .section-default .section-default--overview__bla ... this is not BEM anymore
//     }
//   }
// }

.section-default--overview {
  @at-root .section-default#{&} {
    // compiles to .section-default .section-default--overview
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    max-width: none;

    p {
      padding: 0;
    }

    @media (min-width: $screen-width-767) {
      padding-left: 0;
      padding-right: 0;
      overflow: hidden;
    }

    .background {
      z-index: -1;
      position: absolute;
      display: none;

      @media (min-width: $screen-width-767) {
        display: flex;
        bottom: 0;

        &:before {
          position: absolute;
          z-index: 1;
          content: '';
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(255, 255, 255, 0.8);
        }
      }
    }

    .section-default__bodytext .subheadline {
      text-align: left;
      font-size: 1.65rem;
      word-break: break-word;
    }

    .section-default__bodytext td .subheadline {
      word-break: normal;
    }

    .visual {
      margin: 0 auto;
      display: none;

      @media (min-width: $screen-width-767) {
        display: flex;
        margin: auto 0;
      }
    }

    .embedLink--overview {
      position: relative;
      width: 100%;
      max-width: 600px;
      margin: 0 auto 2rem auto;
    }
  }
}
</style>
