<template>
  <div class="sections">
    <template v-for="(section, index) in structuredSections">
      <div :is="sanitizeSectionTemplate(section.attributes.template)" :key="index" :section="section">
        <SectionsLoop v-if="section.children && section.children.length" :sections="section.children" />
      </div>
    </template>
  </div>
</template>

<script>
import PageHeader from '@/components/header/default'
import buildSectionsStructure from '@/components/article/buildSectionsStructure'
import DefaultSection from '@/components/article/sections/default'
import DefaultProductSection from '@/components/article/sections/default-product'
import ColumnSection from '@/components/article/sections/column'
import HeaderSection from '@/components/article/sections/header'
import OfferSection from '@/components/article/sections/offer'
import OffersSection from '@/components/article/sections/offers'
import OverviewSection from '@/components/article/sections/overview'
import TestimonialSection from '@/components/article/sections/testimonial'
import TestimonialsSection from '@/components/article/sections/testimonials'
import DownloadsSection from '@/components/article/sections/downloads'
import ContactSection from '@/components/article/sections/contact'
import GtvVideoloopSection from '@/components/article/sections/gtv-videoloop'
import PartnersSection from '@/components/article/sections/partners'
import PartnerSection from '@/components/article/sections/partner'
import ContactPartnerSection from '@/components/article/sections/contact-partner'
import FullWidthSection from '@/components/article/sections/full-width-image'

export default {
  name: 'SectionsLoop',
  components: {
    PageHeader,
    DefaultSection,
    DefaultProductSection,
    ColumnSection,
    HeaderSection,
    OfferSection,
    OffersSection,
    OverviewSection,
    TestimonialSection,
    TestimonialsSection,
    DownloadsSection,
    ContactSection,
    GtvVideoloopSection,
    PartnersSection,
    PartnerSection,
    ContactPartnerSection,
    FullWidthSection,
  },
  props: {
    sections: {
      type: Array,
      required: true,
    },
  },
  computed: {
    structuredSections() {
      return this.buildSectionsStructure(this.sections)
    },
  },
  methods: {
    sanitizeSectionTemplate(template) {
      let name = template.replace(/cms\.apoverlag\.article\._sections\./, '')
      name = name.replace(/-([a-z])/g, function (g) {
        return g[1].toUpperCase()
      })
      return name + 'Section'
    },
    buildSectionsStructure,
  },
}
</script>

<style lang="scss">
@import '../../css/import/vars';

.sections {
  width: 100%;

  &-loop-column {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $screen-width-767) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;

      section {
        width: 48%;
        max-width: 500px;
      }
    }
  }
}
</style>
