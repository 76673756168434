<template>
  <section :id="`section-${section.id}`" class="section-default section-default--testimonials">
    <SubHeadline v-if="section.attributes.values.title" :tag="`h${headlineOffset}`">
      {{ section.attributes.values.title }}
    </SubHeadline>
    <template v-if="section.attributes.values.backgroundImage !== '1'">
      <CaptionImage
        v-for="(image, index) in section.attributes.images"
        :key="index"
        :image="image"
        :width="980"
        :height="540"
        :mobile-width="375"
        :mobile-height="320"
        lazy
      >
        {{ image.attributes.description }}
        <span v-if="image.attributes.copyright" class="caption-image__copyright">
          &copy; {{ image.attributes.copyright }}
        </span>
      </CaptionImage>
    </template>
    <LeadText v-if="section.attributes.values.leadText" class="article-leadtext">
      {{ section.attributes.values.leadText }}
    </LeadText>
    <Spunq
      v-if="section.attributes.values.bodyText"
      class="bodytext"
      :text="section.attributes.values.bodyText"
      :inline-objects="section.relationships.inlineObjects"
      :inline-images="section.attributes.inlineImages"
      :headline-offset="headlineOffset"
    />
    <slot />
    <div v-if="section.attributes.values.backgroundImage === '1'" class="background">
      <SpunqImage
        v-for="(image, index) in section.attributes.images.slice(0, 1)"
        :key="index"
        :image="image"
        :width="1440"
        :height="600"
        mode="crop"
      />
    </div>
  </section>
</template>

<script>
import SpunqImage from '../../ui/image/spunq-image'
import SubHeadline from '@/components/ui/text/subheadline'
import LeadText from '@/components/ui/text/lead-text'
import Spunq from '@/components/spunq-tags/spunq'
import CaptionImage from '@/components/ui/image/caption-image'

export default {
  components: {
    SubHeadline,
    LeadText,
    Spunq,
    SpunqImage,
    CaptionImage,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    headlineOffset() {
      if (this.section.attributes.level === 0) {
        return this.section.attributes.level + 2
      } else {
        return this.section.attributes.level + 1
      }
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.section-default--testimonials {
  @at-root .section-default#{&} {
    // compiles to .section-default.section-default--testimonials
    position: relative;
    max-width: 1440px;
    width: 100%;
    overflow: hidden;
  }

  .background {
    position: absolute;
    z-index: -1;
    bottom: 0;
    // left: -165px;
    width: 1440px;
  }

  .bodytext {
    text-align: center;
  }
  //  &__bla {
  //    color: red; // compiles to .section-default--overview__bla YEAH! BEM again.
  //  }
}
</style>
