<template>
  <section :id="`section-${section.id}`" class="section-default section-default--gtv-videoloop">
    <header class="section-header">
      <SubHeadline v-if="section.attributes.values.title" :tag="`h${headlineOffset}`">
        {{ section.attributes.values.title }}
      </SubHeadline>
    </header>
    <div class="video-box">
      <video src="/video/apo_loop.mp4" controls preload="auto" poster="/video/apo_loop_poster.jpg">
        Die Wiedergabe diese Videos wird von diesem Browser nicht unterstützt.
      </video>
    </div>
  </section>
</template>

<script>
import SubHeadline from '@/components/ui/text/subheadline'

export default {
  components: {
    SubHeadline,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  computed: {
    headlineOffset() {
      return this.section.attributes.level === 0 ? this.section.attributes.level + 2 : this.section.attributes.level + 1
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.section-default--gtv-videoloop {
  @at-root .section-default#{&} {
    .video-box {
      position: relative;
      display: block;
      width: 100%;
      padding: 0 20px;

      @media screen and (min-width: $screen-width-1110) {
        padding: 0;
      }

      video {
        width: 100%;
        display: block;
      }
    }
  }
}
</style>
