<template>
  <section :id="`section-${section.id}`" class="section-default section-default--contact">
    <div class="section-default--contact__content">
      <SubHeadline :tag="`h${headlineOffset}`">
        {{ section.attributes.values.title || 'Kontakt' }}
      </SubHeadline>
      <LeadText v-if="section.attributes.values.leadText" class="section-default--contact__leadtext">
        {{ section.attributes.values.leadText }}
      </LeadText>
      <LoadSpinner v-if="contactInquiryLoading" />
      <SectionContactForm
        class="section-default--contact__form"
        :section-id="section.id"
        @contact-inquiry-loading="(value) => (contactInquiryLoading = value)"
      />
      <slot />
    </div>
  </section>
</template>

<script>
import SubHeadline from '@/components/ui/text/subheadline'
import SectionContactForm from '@/components/contact/section-contact-form'
import LoadSpinner from '@/components/ui/loader/spinner'
import LeadText from '@/components/ui/text/lead-text'

export default {
  components: {
    SubHeadline,
    SectionContactForm,
    LoadSpinner,
    LeadText,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contactInquiryLoading: false,
    }
  },
  computed: {
    headlineOffset() {
      return this.section.attributes.level === 0 ? this.section.attributes.level + 2 : this.section.attributes.level + 1
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.section-default--contact {
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $color-bg-gray;
    padding: 60px 0 40px 0;
  }

  &__form {
    width: 100%;
    max-width: 540px;
    padding: 0 20px;
  }

  &__leadtext {
    text-align: center;
  }
}
</style>
