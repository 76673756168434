<template>
  <div class="contact-form">
    <form v-if="!contactInquirySuccess" @submit.prevent="handleSubmit">
      <NamedInput v-model="formdata.pharmacy" class="contact-form__input-field">
        <template #label> Firma </template>
      </NamedInput>
      <NamedInput
        v-model="formdata.name"
        :class="{ 'form-group--error': $v.formdata.name.$error }"
        class="contact-form__input-field"
        :required="true"
        @input="$v.formdata.name.$reset()"
        @blur="$v.formdata.name.$touch()"
      >
        <template #label> Name </template>
        <template #error>
          <span v-if="$v.formdata.name.$error && !$v.formdata.name.required" class="error-message error">
            {{ errorMessages.required }}
          </span>
        </template>
      </NamedInput>
      <NamedInput v-model="formdata.phone" class="contact-form__input-field">
        <template #label> Telefonnumer </template>
      </NamedInput>
      <NamedInput
        v-model="formdata.email"
        :class="{ 'form-group--error': $v.formdata.email.$error }"
        class="contact-form__input-field"
        :required="true"
        @input="$v.formdata.email.$reset()"
        @blur="$v.formdata.email.$touch()"
      >
        <template #label> E-Mail </template>
        <template #error>
          <span v-if="$v.formdata.email.$error && !$v.formdata.email.required" class="error-message error">
            {{ errorMessages.required }}
          </span>
          <span v-if="$v.formdata.email.$error && !$v.formdata.email.email" class="error-message error">
            {{ errorMessages.email }} (z.B. name@gmail.com)
          </span>
        </template>
      </NamedInput>
      <NamedTextarea
        v-model="formdata.message"
        :class="{ 'form-group--error': $v.formdata.message.$error }"
        :rows="5"
        :required="true"
        :value="formdata.message"
        @input="$v.formdata.message.$reset()"
        @blur="$v.formdata.message.$touch()"
      >
        <template #label> Nachricht </template>
        <template #error>
          <span v-if="$v.formdata.message.$error && !$v.formdata.message.required" class="error-message error">
            {{ errorMessages.required }}
          </span>
        </template>
      </NamedTextarea>
      <span v-if="$v.formdata.$error" class="error-message error">
        {{ errorMessages.form }}
      </span>
      <DefaultButton class="contact-form__submit-button" aria-label="Kontakformular absenden"> Absenden </DefaultButton>
    </form>
    <SuccessModal v-else>
      Ihre Nachricht wurde erfolgreich übermittelt!
      <button class="contact-form__button" @click="resetForm()">Zurück zum Formular</button>
    </SuccessModal>
    <span v-if="contactInquiryError" class="contact-form__error error">
      Es ist ein Fehler aufgetreten und die Daten konnten nicht gesendet werden. Das kann verschiedene Ursachen
      haben.<br /><br />
      Bitte versuchen Sie es später nocheinmal!
    </span>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import CONTACT from '@/gql/contact.gql'

import NamedInput from '@/components/ui/form/input/named'
import NamedTextarea from '@/components/ui/form/textarea/named'
import DefaultButton from '@/components/ui/button/default'
import SuccessModal from '@/components/ui/modal/success'
import { FORM_ERROR_GENERAL, FORM_ERROR_FIELD_REQUIRED, FORM_ERROR_INCORRECT_FORMAT } from '@/utils/messages'

export default {
  components: {
    NamedInput,
    NamedTextarea,
    DefaultButton,
    SuccessModal,
  },
  props: {
    sectionId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      contactInquirySuccess: false,
      contactInquiryLoading: false,
      contactInquiryError: false,
      formdata: {
        pharmacy: '',
        name: '',
        phone: '',
        email: '',
        message: '',
        sectionId: this.sectionId,
      },
      errorMessages: {
        form: FORM_ERROR_GENERAL,
        required: FORM_ERROR_FIELD_REQUIRED,
        email: FORM_ERROR_INCORRECT_FORMAT,
      },
    }
  },
  validations: {
    formdata: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      message: {
        required,
      },
    },
  },
  methods: {
    handleSubmit() {
      const { $v } = this
      $v.$touch()
      if (!$v.$error) {
        this.sendContactInquiry()
      }
    },
    async sendContactInquiry() {
      try {
        this.contactInquiryLoading = true
        this.$emit('contact-inquiry-loading', true)

        const client = this.$apolloProvider.defaultClient

        const { data } = await client.mutate({
          mutation: CONTACT,
          variables: {
            data: this.formdata,
          },
        })

        if (data !== undefined && data.sendContactMessage !== undefined && data.sendContactMessage.data.status) {
          this.contactInquirySuccess = true
        }
      } catch (e) {
        this.contactInquiryError = true
        // eslint-disable-next-line no-console
        console.error(e)
      } finally {
        this.contactInquiryLoading = false
        this.$emit('contact-inquiry-loading', false)
      }
    },
    resetForm() {
      const { $v } = this

      $v.formdata.$reset()

      this.contactInquiryLoading = false
      this.contactInquiryError = false
      this.contactInquirySuccess = false

      this.formdata.pharmacy = ''
      this.formdata.name = ''
      this.formdata.phone = ''
      this.formdata.email = ''
      this.formdata.message = ''
    },
  },
}
</script>

<style lang="scss">
@import '../../css/import/vars';

.contact-form {
  &__input-field {
    margin-bottom: 1rem;
  }

  &__submit-button {
    width: 100%;
    padding: 0 2rem;
    margin: 2rem auto;
  }

  .error-message {
    display: block;
    font-size: 14px;
    margin: 5px 2px 0 2px;
    line-height: 130%;
  }

  &__error {
    text-align: center;
    padding: 0 20px;
    max-width: 540px;
  }

  &__button {
    margin-top: 40px;
  }
}
</style>
