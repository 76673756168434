<template>
  <article v-if="article.relationships.sections.length" id="top" :class="['product-article', productCategory]">
    <ProductHeader :first-section="firstSection" :category="productCategory" />
    <ProductNavigation v-if="sectionsAfterOpener.length > 0" :sections="sectionsAfterOpener" />
    <SectionsLoopProductpages v-if="sectionsAfterOpener.length > 0" :sections="sectionsAfterOpener" />
  </article>
</template>

<script>
import SectionsLoopProductpages from '@/components/article/sections-productpages'
import ProductHeader from '@/components/article/product-header'
import ProductNavigation from '@/components/article/product-navigation'

export default {
  components: {
    SectionsLoopProductpages,
    ProductHeader,
    ProductNavigation,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
  computed: {
    productCategory() {
      if (this.article.attributes.options && this.article.attributes.options.category !== null) {
        return this.article.attributes.options.category.toLowerCase()
      } else {
        return 'default'
      }
    },
    sectionsAfterOpener() {
      return this.article.relationships.sections.slice(1)
    },
    firstSection() {
      return this.article.relationships.sections[0]
    },
  },
}
</script>

<style lang="scss">
@import '../../css/import/vars';

.product-article {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  .level1 {
    position: relative !important;
    padding-bottom: 1rem;

    @media screen and (min-width: $screen-width-767) {
      padding-bottom: 3rem;
    }
  }

  .section-header {
    margin-bottom: 20px;
  }

  h2.subheadline {
    font-size: 1.5rem;
    font-weight: 300;
    text-align: center;
    margin-bottom: 0.5rem;
    word-break: break-word;

    @supports (font-variation-settings: normal) {
      font-variation-settings: 'wght' 300;
    }
  }

  h3.subheadline {
    font-size: 20px;
    font-weight: 600;
    color: $color-product-text;
    text-align: center;

    @supports (font-variation-settings: normal) {
      font-variation-settings: 'wght' 600;
    }

    @media screen and (min-width: $screen-width-767) {
      font-size: 20px;
    }
  }
  .article-leadtext {
    font-size: 1.2rem;
    line-height: 36px;
    text-align: center;
    color: $color-product-leadtext;
    margin-bottom: 3rem;
    font-weight: 300;
    word-break: break-word;

    @supports (font-variation-settings: normal) {
      font-variation-settings: 'wght' 300;
    }
  }
  .responsive-image {
    position: relative;

    .caption-image__copyright {
      position: absolute;
      bottom: 10px;
      right: 10px;
      color: $color-white;
      line-height: 1;
      font-size: 12px;
    }
  }
  .section-default__bodytext {
    margin: 0 auto;
    text-align: left;
    width: 100%;
    max-width: $overview-body-max-width-600;
  }

  &.software {
    a {
      color: $color-software;
    }
    h1,
    h2 {
      color: $color-software;
    }
    .button,
    button,
    ul li:before {
      background: $color-software;
    }
    .button,
    button {
      color: $color-white;
    }
    .productpage-navigation__link {
      &.active {
        border-bottom: 3px solid $color-software;
      }

      @media screen and (min-width: $screen-width-1023) {
        &:hover {
          border-bottom: 3px solid $color-software;
        }
      }
    }
    button.inverted-colors {
      background-color: $color-white;
      color: $color-software;
    }
    .buttonLink {
      background-color: $color-software;
    }
  }

  &.fortbildung {
    a {
      color: $color-fortbildung;
    }
    h1,
    h2 {
      color: $color-fortbildung;
    }
    .button,
    button,
    ul li:before {
      background: $color-fortbildung;
    }
    .button,
    button {
      color: $color-white;
    }
    .productpage-navigation__link {
      &.active {
        border-bottom: 3px solid $color-fortbildung;
      }

      @media screen and (min-width: $screen-width-1023) {
        &:hover {
          border-bottom: 3px solid $color-fortbildung;
        }
      }
    }
    button.inverted-colors {
      background-color: $color-white;
      color: $color-fortbildung;
    }
    .buttonLink {
      background-color: $color-fortbildung;
    }
  }

  &.medien {
    a {
      color: $color-medien;
    }
    h1,
    h2 {
      color: $color-medien;
    }
    .button,
    button,
    ul li:before {
      background: $color-medien;
    }
    .button,
    button {
      color: $color-white;
    }
    .productpage-navigation__link {
      &.active {
        border-bottom: 3px solid $color-medien;
      }

      @media screen and (min-width: $screen-width-1023) {
        &:hover {
          border-bottom: 3px solid $color-medien;
        }
      }
    }
    button.inverted-colors {
      background-color: $color-white;
      color: $color-medien;
    }
    .buttonLink {
      background-color: $color-medien;
    }
  }

  &.pharmainfo {
    a {
      color: $color-pharmainfo;
    }
    h1,
    h2 {
      color: $color-pharmainfo;
    }
    .button,
    button,
    ul li:before {
      background: $color-pharmainfo;
    }
    .button,
    button {
      color: $color-white;
    }
    .productpage-navigation__link {
      &.active {
        border-bottom: 3px solid $color-pharmainfo;
      }

      @media screen and (min-width: $screen-width-1023) {
        &:hover {
          border-bottom: 3px solid $color-pharmainfo;
        }
      }
    }
    button.inverted-colors {
      background-color: $color-white;
      color: $color-pharmainfo;
    }
    .buttonLink {
      background-color: $color-pharmainfo;
    }
  }
  /* end product colors */

  p,
  li {
    color: $color-product-text;
    font-size: 18px;
    line-height: 1.45;
    margin-bottom: 1rem;
  }

  h2,
  h3,
  h4,
  p,
  ul,
  ol {
    padding: 0 20px;

    @media screen and (min-width: 1140px) {
      /* our content is 1110px wide, let's add some 30px and that is our breakpoint. now text should never stick on the pageborders */
      padding: 0;
    }
  }

  .button {
    margin: 0 auto;
    color: $color-white;
    width: fit-content;
  }

  .section-default {
    @media screen and (min-width: $screen-width-767) {
      padding-top: 80px; // needed for inline-navigation anchors - content starts below sticky navigation header
      margin-top: -3rem; // cannot be bigger than bottom padding of sections above - otherwise their bottom elements are not clickable
    }
  }

  .buttonLink {
    display: flex;
    width: fit-content;

    @media screen and (min-width: $screen-width-767) {
      display: inline-flex;
    }
  }

  a .buttonLink,
  .buttonLink a {
    color: $color-white;
    text-align: center;
  }
}
</style>
