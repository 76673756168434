<template>
  <section :id="`section-${section.id}`" class="section-default section-default--partners">
    <header class="section-header">
      <SubHeadline v-if="section.attributes.values.title" :tag="`h${headlineOffset}`">
        {{ section.attributes.values.title }}
      </SubHeadline>
    </header>
    <slot />
  </section>
</template>

<script>
import SubHeadline from '@/components/ui/text/subheadline'

export default {
  components: {
    SubHeadline,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  computed: {
    headlineOffset() {
      const { level } = this.section.attributes
      return level === 0 ? level + 2 : level + 1
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.section-default--partners {
  @at-root .section-default#{&} {
    // compiles to .section-default.section-default--partners

    .sections {
      width: 100%;
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-wrap: wrap;

      @media (min-width: $screen-width-767) {
        flex-direction: row;
      }
    }
  }
}
</style>
