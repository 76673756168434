<template>
  <div class="sections">
    <div class="product-section-headercontainer">
      <PageHeader :logo="category" />
      <div
        :is="sanitizeSectionTemplate(firstSection.attributes.template)"
        :observe-data="`section-${firstSection.id}`"
        :section="firstSection"
        :class="[{ l1: firstSection.attributes.level === 1 }, `level${firstSection.attributes.level}`]"
      >
        <SectionsLoopProductpages
          v-if="firstSection.children && firstSection.children.length"
          :sections="firstSection.children"
          :class="[
            { 'sections-loop-column': sanitizeSectionTemplate(firstSection.attributes.template) === 'columnSection' },
          ]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/header/default'
import DefaultSection from '@/components/article/sections/default'
import DefaultProductSection from '@/components/article/sections/default-product'
import ColumnSection from '@/components/article/sections/column'
import HeaderSection from '@/components/article/sections/header'
import OfferSection from '@/components/article/sections/offer'
import OffersSection from '@/components/article/sections/offers'
import OverviewSection from '@/components/article/sections/overview'
import TestimonialSection from '@/components/article/sections/testimonial'
import TestimonialsSection from '@/components/article/sections/testimonials'

export default {
  name: 'SectionsLoopProductpages',
  components: {
    PageHeader,
    DefaultSection,
    DefaultProductSection,
    ColumnSection,
    HeaderSection,
    OfferSection,
    OffersSection,
    OverviewSection,
    TestimonialSection,
    TestimonialsSection,
  },
  props: {
    firstSection: {
      type: Object,
      required: true,
    },
    category: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      active: false,
      isTop: false,
    }
  },
  methods: {
    sanitizeSectionTemplate(template) {
      let name = template.replace(/cms\.apoverlag\.article\._sections\./, '')
      name = name.replace(/-([a-z])/g, function (g) {
        return g[1].toUpperCase()
      })
      return name + 'Section'
    },
  },
}
</script>

<style lang="scss">
@import '../../css/import/vars';

.sections {
  width: 100%;

  .product-section-headercontainer {
    padding-top: 120px; // header is fixed

    @media screen and (min-width: $screen-width-767) {
      padding-top: 0; // header is sticky
    }
  }

  .page-header {
    position: fixed;

    @media screen and (min-width: $screen-width-767) {
      position: relative;
      position: sticky;
      top: 0;
    }
  }
}
</style>
