<template>
  <div class="file-card" :title="fileName">
    <div class="file-card__content">
      <p v-snip="2" class="file-card__title">
        {{ title }}
      </p>
      <p v-snip="3" class="file-card__description">
        {{ description }}
      </p>
    </div>
    <a :href="downloadUrl" target="_blank">
      <button class="file-card__download">Download</button>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fileId() {
      return this.file.attributes.fileId
    },
    fileName() {
      return this.file.attributes.fileName
    },
    title() {
      return this.file.attributes.title
    },
    description() {
      return this.file.attributes.description
    },
    downloadUrl() {
      const fileServiceBaseUrls = this.$config.fileServiceBaseUrls
      return `${fileServiceBaseUrls[this.fileId % fileServiceBaseUrls.length]}/${this.fileId}-${this.fileName}`
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

$file-card-width: 275px;

.file-card {
  background-color: $color-white;
  width: $file-card-width;
  height: $file-card-width * 0.93;
  margin: 0 20px 40px 20px;
  padding: 30px 20px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &__title {
    padding: 0 30px;
    text-align: center;
    overflow: hidden;
  }

  &__description {
    text-align: center !important;
    font-size: 16px !important;
    color: $color-text-gray !important;
  }

  &__download {
    padding-left: 20px;
    padding-right: 20px;
  }

  &__content {
    max-width: 100%;
  }
}
</style>
