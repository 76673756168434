<template>
  <div class="author">
    <NuxtLink
      :to="{ name: 'author-canonical', params: { id: author.id, authorName: authorSlug } }"
      class="author__link"
    >
      <p class="author__name">
        von
        <template v-if="author.attributes.prefixTitle"> {{ author.attributes.prefixTitle }} </template
        ><template v-if="author.attributes.firstName"> {{ author.attributes.firstName }} </template
        ><template v-if="author.attributes.lastName"> {{ author.attributes.lastName }} </template
        ><template v-if="author.attributes.suffixTitle">
          {{ author.attributes.suffixTitle }}
        </template>
      </p>
    </NuxtLink>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    author: {
      type: Object,
      required: true,
    },
  },
  computed: {
    authorSlug() {
      return this.$slugify(`${this.author.attributes.firstName} ${this.author.attributes.lastName}`)
    },
  },
}
</script>

<style lang="scss">
@import '../../css/import/vars';

.author {
  display: flex;
  align-items: center;
  justify-content: center;

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 2px solid $color-white;
    overflow: hidden;
    margin-right: 5px;
  }

  &__name {
    color: $color-white;
    font-size: 14px;
    margin: 0;
  }
}
</style>
