export default function (sections) {
  if (!sections.length) {
    return []
  }
  if (sections.length === 1) {
    return sections
  }
  const clonedSections = sections.map((section) => JSON.parse(JSON.stringify(section)))
  let currentSection = clonedSections[0]
  const resultingSections = []
  clonedSections.forEach((section) => {
    if (section.attributes.level <= currentSection.attributes.level && section.attributes.level !== 0) {
      // we have the same or higher level
      currentSection = section
      resultingSections.push(section)
      return
    }
    // we have lower level sections, add it to the children of the section
    if (typeof currentSection.children === 'undefined') {
      currentSection.children = []
    }
    currentSection.children.push(section)
  })
  return resultingSections
}
