<template>
  <section :id="`section-${section.id}`" class="section-default section-downloads">
    <SubHeadline v-if="sectionTitle" :tag="`h${headlineOffset}`">
      {{ sectionTitle }}
    </SubHeadline>
    <LeadText v-if="section.attributes.values.leadText" class="article-leadtext">
      {{ section.attributes.values.leadText }}
    </LeadText>

    <div class="section-downloads__files">
      <FileDownloadCard v-for="file in files" :key="file.id" :file="file" />
    </div>
  </section>
</template>

<script>
import SubHeadline from '@/components/ui/text/subheadline'
import FileDownloadCard from '@/components/ui/file/file-download-card'
import LeadText from '@/components/ui/text/lead-text'

export default {
  components: {
    SubHeadline,
    LeadText,
    FileDownloadCard,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sectionTitle() {
      return this.section.attributes.values.title
    },
    files() {
      return this.section.relationships.files
    },
    headlineOffset() {
      const { level } = this.section.attributes
      return level === 0 ? 2 : level + 1
    },
  },
}
</script>

<style lang="scss">
.section-downloads {
  &__files {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
</style>
