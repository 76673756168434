<template>
  <article class="default-article">
    <header v-if="article.attributes.images.length > 0" class="default-article-header default-article-header--hasImage">
      <SpunqImage
        :image="article.attributes.images[0]"
        :width="1110"
        :height="480"
        :mobile-width="375"
        :mobile-height="320"
        mode="crop"
      />
      <ImageCopyright :image="article.attributes.images[0]" />
      <div class="default-article-header__infos">
        <h1>
          {{ article.attributes.title }}
        </h1>
        <AuthorElement v-if="article.relationships.authors.length > 0" :author="article.relationships.authors[0]" />
      </div>
    </header>
    <header v-else class="default-article-header">
      <h1>
        {{ article.attributes.title }}
      </h1>
      <AuthorElement v-if="article.relationships.authors.length > 0" :author="article.relationships.authors[0]" />
    </header>
    <div class="default-article-body">
      <section>
        <div class="default-article-body__top">
          <DateElement :date="article.attributes.frontendDate" class="article-date" />
          <ArticlePrint class="article-print" />
        </div>
        <LeadText v-if="article.attributes.leadText" class="leadtext">
          {{ article.attributes.leadText }}
        </LeadText>
        <Spunq
          :text="article.attributes.bodyText"
          :inline-objects="article.relationships.inlineObjects"
          :inline-images="article.attributes.inlineImages"
          :headline-offset="1"
        />
        <div v-if="article.relationships.files" class="article__files">
          <FileDownload
            v-for="file in article.relationships.files"
            :id="file.attributes.fileId"
            :key="file.id"
            :type="file.attributes.mimeType"
            :title="file.attributes.title"
          />
        </div>
      </section>
      <SectionsLoop v-if="article.relationships.sections.length > 0" :sections="article.relationships.sections" />
    </div>
  </article>
</template>

<script>
import SpunqImage from '@/components/ui/image/spunq-image'
import Spunq from '@/components/spunq-tags/spunq'
import ImageCopyright from '@/components/ui/image/copyright'
import AuthorElement from '@/components/author/default'
import LeadText from '@/components/ui/text/lead-text'
import DateElement from '@/components/article/date'
import FileDownload from '@/components/ui/file/file-download'
import SectionsLoop from '@/components/article/sections'
import ArticlePrint from '@/components/article/print'
// import ChannelLink from '@/components/channel/link'
// import ArticleLink from '@/components/article/link'

export default {
  components: {
    SpunqImage,
    Spunq,
    ImageCopyright,
    AuthorElement,
    LeadText,
    DateElement,
    FileDownload,
    SectionsLoop,
    ArticlePrint,
    // ChannelLink,
    // ArticleLink,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '../../css/import/vars';

.default-article {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 80px auto 0 auto;
  @media all and (min-width: $screen-width-1023) {
    margin-top: 152px;
  }
  @media print {
    margin-top: 0;
  }

  &-header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: $default-article-width;
    width: 100%;
    padding: 1rem 0;

    @media screen and (min-width: $screen-width-767) {
      padding: 2rem 0;
    }

    h1 {
      color: $color-text;
      text-align: left;
      padding: 1rem 0.5rem;
      font-weight: 200;
      font-variation-settings: 'wght' 200;
      font-size: 42px;
      line-height: 48px;
      @media screen and (min-width: $screen-width-767) {
        padding: 1rem 0.5rem;
      }
      @media screen and (min-width: $screen-width-1023) {
        line-height: 120%;
        font-size: 58px;
      }
    }

    &--hasImage {
      padding: 0;
      margin-bottom: 1rem;
      max-width: $content-max-width;

      @media screen and (min-width: $screen-width-767) {
        margin-bottom: 3rem;
      }

      &:before {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.2);
      }

      h1 {
        padding: 1rem;

        @media screen and (min-width: $screen-width-767) {
          padding: 1rem 0.5rem;
        }
      }
    }

    .author {
      width: 100%;
      text-align: left;
      justify-content: flex-start;
      padding: 0 0.5rem;

      &__name {
        color: $color-text;
      }
    }

    &__infos {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @media screen and (min-width: $screen-width-767) {
        justify-content: flex-end;
        margin-bottom: 6rem;
      }

      h1 {
        width: 100%;
        padding: 1rem;
        color: $color-white;
        text-align: center;
      }

      .author {
        width: 100%;
        text-align: center;
        justify-content: center;

        &__name {
          color: $color-white;
        }
      }
    }
  }

  &-body {
    width: 100%;
    max-width: $default-article-width;
    padding: 0 0.5rem;

    &__top {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 1rem;

      @media all and (min-width: $screen-width-767) {
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
      }

      .article-date {
        margin: 0;
      }

      .article-print {
        margin: 0;
      }
    }

    .article__files {
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;
    }

    .subheadline {
      font-size: 20px;
      font-weight: 800;

      @supports (font-variation-settings: normal) {
        font-variation-settings: 'wght' 800;
      }

      @media screen and (min-width: $screen-width-767) {
        font-size: 30px;
      }
    }

    /* handle product section in news article ... editors should not use the product sections ... but we forgive and style the sections a little bit */

    .section-default.section-default--header {
      position: relative;
      max-height: none;
      height: auto;
      flex-direction: column;
    }
    .section-default .background {
      display: none;
    }
    .section-default .lead {
      width: auto;
      height: auto;
      max-width: none;
      margin: 0;
    }
    .section-default--header .lead p {
      font-size: 18px;
      line-height: inherit;
    }
  }
}
</style>
