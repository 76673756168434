<template>
  <div>
    <div v-if="article && isProduct">
      <ProductArticle :article="article" />
    </div>
    <div v-else>
      <PageHeader />
      <DefaultArticle :article="article" />
    </div>
  </div>
</template>

<script>
import ARTICLE from '@/gql/article'
import { generateMetaTags, generateTitle } from '@/utils/head-generators'
import { parseChannelSlugs, slugify } from '@/plugins/url-utils'
import DefaultArticle from '@/components/article/article-default'
import ProductArticle from '@/components/article/article-product'
import PageHeader from '@/components/header/default'

export default {
  components: {
    DefaultArticle,
    ProductArticle,
    PageHeader,
  },
  layout: 'article',
  async asyncData({ app, route, redirect, error, $config: { fixedArticleIds, portalId } }) {
    try {
      const client = app.apolloProvider.defaultClient
      const { id } = route.params

      // check for fixed article redirect
      for (const key in fixedArticleIds) {
        if (+fixedArticleIds[key] === +id) {
          redirect(301, app.router.resolve({ name: key }).resolved.path)
        }
      }

      const { data } = await client.query({
        query: ARTICLE,
        variables: {
          articleId: parseInt(id),
          token: route.query.token,
        },
      })

      if (data.getArticleById === null) {
        return error({ statusCode: 404 })
      }

      // check for redirect
      const channelSlugs = parseChannelSlugs(data.getArticleById.data.relationships.channels, portalId)
      const customSlug = data.getArticleById.data.attributes.urlSlug
      const articleSlug = customSlug || slugify(data.getArticleById.data.attributes.pageTitle)
      const articleId = data.getArticleById.data.id
      const link = app.router.resolve({
        name: 'article-canonical',
        params: {
          id: articleId,
          channelSlugs,
          slug: articleSlug,
        },
      })

      if (link.resolved.path !== route.path) {
        return redirect(301, link.resolved.path)
      }

      return {
        article: data.getArticleById.data,
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      return error({ statusCode: 500 })
    }
  },
  data() {
    return {
      article: null,
      showAds: false,
    }
  },
  head() {
    return {
      title: generateTitle(this.article.attributes.title, this.$config.pageTitle),
      meta: generateMetaTags(
        this.$config.pageTitle,
        this.$config.baseUrl,
        this.$config.imageServiceBaseUrls,
        this.article.attributes.title,
        this.article.attributes.robotsDescription,
        this.$route.path,
        this.article.attributes.images[0] ? this.article.attributes.images[0] : '/og-default.jpg',
        this.article.attributes.images[0] ? 1110 : 512,
        this.article.attributes.images[0] ? 480 : 512
      ),
    }
  },
  computed: {
    isProduct() {
      if (this.article.attributes.overrideLayout) {
        return true
      }
      const homeChannelSlug = this.article.relationships.channels[0].attributes.slug
      const channels = this.article.relationships.channels[0].relationships.parents
      if (
        channels.filter((channel) => channel.attributes.slug === 'produkte').length > 0 ||
        homeChannelSlug === 'produkte'
      ) {
        return true
      } else {
        return false
      }
    },
  },
}
</script>
