<template>
  <section :id="`section-${section.id}`" class="section-default section-contact-partner">
    <SubHeadline :tag="`h${headlineOffset}`" class="title">
      {{ section.attributes.values.title || 'Ansprechpartner' }}
    </SubHeadline>
    <!-- <h2 class="title"></h2> -->
    <ul class="contact-partner-list">
      <li v-for="partner in section.relationships.authors" :key="partner.id" class="contact-partner">
        <SpunqImage
          v-if="partner.attributes.image"
          :image="partner.attributes.image"
          :width="120"
          :height="100"
          :mobile-width="120"
          :mobile-height="100"
          class="contact-person-portrait"
        />
        <div class="contact-person-content">
          <h3 class="fullname">{{ partner.attributes.firstName }} {{ partner.attributes.lastName }}</h3>
          <p class="function">
            {{ partner.attributes.profileText }}
          </p>
          <a :href="`tel:${partner.attributes.phoneNumber}`" class="tel">{{ partner.attributes.phoneNumber }}</a>
          <a :href="`mailto:${partner.attributes.email}`" class="email">{{ partner.attributes.email }}</a>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
import SpunqImage from '@/components/ui/image/spunq-image'
import SubHeadline from '@/components/ui/text/subheadline'

export default {
  components: {
    SpunqImage,
    SubHeadline,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  computed: {
    headlineOffset() {
      const { level } = this.section.attributes
      return level === 0 ? level + 2 : level + 1
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.section-contact-partner {
  padding-top: 48px;

  .title.subheadline {
    font-size: 24px;
    text-align: center;
    font-weight: 300;

    @media screen and (min-width: $screen-width-767) {
      font-size: 24px;
    }
  }

  .contact-partner-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    list-style: none;
  }

  .contact-partner {
    display: flex;
    flex-grow: 1;
    padding: 20px 10px;
    border-radius: 6px;
    margin: 10px 20px;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.13);

    @media screen and (min-width: $screen-width-767) {
      margin: 10px 10px;
      padding: 20px 15px;
      min-height: 140px;
      flex-basis: 320px;
      max-width: 420px;
      flex-shrink: 0;
    }

    &::before {
      content: none;
    }
  }

  .contact-person-portrait {
    flex-basis: 80px;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 15px;
    box-sizing: content-box;

    img {
      width: 100%;
      border-radius: 6px;
    }

    @media screen and (min-width: $screen-width-767) {
      padding-right: 10px;
      flex-basis: 100px;
    }
  }

  .contact-person-content {
    .fullname {
      color: $color-headline;
      font-size: 16px;
      font-weight: 600;
      line-height: calc(26 / 22);
      padding: 0;

      @media screen and (min-width: $screen-width-767) {
        font-size: 16px;
      }
    }

    .function {
      padding: 0;
      margin-bottom: 24px;
      color: $color-info-gray;
      font-size: 16px;
    }

    .tel {
      display: block;
      font-size: 16px;
      color: $color-text;
    }

    .email {
      display: block;
      font-size: 16px;
      color: $color-red;
      word-break: break-word;
    }
  }
}
</style>
