<template>
  <section :id="`section-${section.id}`" class="section-default section-default--partner">
    <div class="partner-content">
      <SpunqImage
        v-if="section.attributes.images[0]"
        :image="section.attributes.images[0]"
        :width="300"
        :height="100"
        :mobile-width="300"
        :mobile-height="100"
      />
      <SubHeadline v-if="section.attributes.values.title" :tag="`h${headlineOffset}`" class="partner-content__label">
        <a v-snip="1" :href="section.attributes.values.link" target="_blank" class="partner-content__link">
          {{ section.attributes.values.title }}
        </a>
      </SubHeadline>
    </div>
  </section>
</template>

<script>
import SpunqImage from '@/components/ui/image/spunq-image'
import SubHeadline from '@/components/ui/text/subheadline'

export default {
  components: {
    SpunqImage,
    SubHeadline,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  computed: {
    headlineOffset() {
      const { level } = this.section.attributes
      return level === 0 ? level + 2 : level + 1
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.section-default--partner {
  @at-root .section-default#{&},
    .product-article .section-default#{&} {
    // compiles to
    // .section-default.section-default--partner,
    // .product-article .section-default.section-default--partner
    max-width: 500px;
    margin: 0 auto;

    @media screen and (min-width: $screen-width-767) {
      flex: 0 0 33.333333%;
      min-width: 350px;
      margin: 0;
      padding: 0;
    }

    .partner-content {
      position: relative;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.16);
      border-radius: 5px;
      min-height: 220px;
      padding: 25px 35px;
      margin: 0 15px 30px 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      @media (min-width: $screen-width-767) {
        height: 220px;
      }

      .responsive-image {
        width: auto;
      }

      &__label.subheadline {
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        margin: auto 0 0 0;
      }

      &__link {
        color: $color-headline;
        cursor: pointer;

        &:after {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
    }
  }
}
</style>
