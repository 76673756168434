<template>
  <section :id="`section-${section.id}`" class="section-default section-default--offers">
    <SubHeadline v-if="section.attributes.values.title" :tag="`h${headlineOffset}`" class="section-headline">
      {{ section.attributes.values.title }}
    </SubHeadline>
    <LeadText v-if="section.attributes.values.leadText" class="article-leadtext">
      {{ section.attributes.values.leadText }}
    </LeadText>
    <Spunq
      v-if="section.attributes.values.bodyText"
      :text="section.attributes.values.bodyText"
      :inline-objects="section.relationships.inlineObjects"
      :inline-images="section.attributes.inlineImages"
      :headline-offset="headlineOffset"
    />
    <div v-if="section.relationships.files" class="section-default__files">
      <FileDownload
        v-for="file in section.relationships.files"
        :id="file.attributes.fileId"
        :key="file.id"
        :type="file.attributes.mimeType"
        :title="file.attributes.title"
      />
    </div>
    <div class="section-offers__offers">
      <SpunqImage
        v-if="section.attributes.images.length"
        :image="section.attributes.images[0]"
        :width="1440"
        :height="600"
        :mobile-width="600"
        :mobile-height="400"
        mode="crop"
        class="section-offers__background-image"
        lazy
      />
      <slot />
    </div>
  </section>
</template>

<script>
import SpunqImage from '../../ui/image/spunq-image'
import SubHeadline from '@/components/ui/text/subheadline'
import LeadText from '@/components/ui/text/lead-text'
import Spunq from '@/components/spunq-tags/spunq'
import FileDownload from '@/components/ui/file/file-download'

export default {
  components: {
    SubHeadline,
    LeadText,
    Spunq,
    SpunqImage,
    FileDownload,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  computed: {
    headlineOffset() {
      if (this.section.attributes.level === 0) {
        return this.section.attributes.level + 2
      } else {
        return this.section.attributes.level + 1
      }
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.section-default--offers {
  @at-root .section-default#{&} {
    // compiles to .section-default.section-default--offers
    max-width: $screen-width-1440;

    .sections {
      max-width: 1120px;
      margin: auto;
      display: flex;
      flex-direction: column;

      @media screen and (min-width: $screen-width-767) {
        flex-direction: row;
      }
    }

    .section-headline {
      margin-bottom: 4rem;
    }

    .section-offers {
      &__offers {
        position: relative;
      }

      &__background-image {
        display: none;

        @media screen and (min-width: $screen-width-767) {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          bottom: -3rem;
          right: 0;
          overflow: hidden;
        }
      }
    }
  }
}
</style>
