<template>
  <section :id="`section-${section.id}`" class="section-default section-default--testimonial">
    <div class="testimonial-content">
      <div class="testimonial-content-head">
        <SubHeadline v-if="section.attributes.values.title" :tag="`h${headlineOffset}`">
          {{ section.attributes.values.title }}
        </SubHeadline>
        <LeadText v-if="section.attributes.values.leadText" class="article-leadtext">
          {{ section.attributes.values.leadText }}
        </LeadText>
        <Spunq
          v-if="section.attributes.values.bodyText"
          :text="section.attributes.values.bodyText"
          :inline-objects="section.relationships.inlineObjects"
          :inline-images="section.attributes.inlineImages"
          :headline-offset="headlineOffset"
          class="testimonial-content__bodytext"
        />
      </div>
      <div class="testimonial-person">
        <CaptionImage
          v-if="section.attributes.images.length > 0"
          class="testimonial-person__image"
          :image="section.attributes.images[0]"
          :width="120"
          :height="120"
          :mobile-width="120"
          :mobile-height="120"
          mode="crop"
        />
        <div class="testimonial-person__data">
          <div v-if="section.attributes.values.person" class="name">
            {{ section.attributes.values.person }}
          </div>
          <div v-if="section.attributes.values.role" class="role">
            {{ section.attributes.values.role }}
          </div>
        </div>
      </div>
      <slot />
    </div>
  </section>
</template>

<script>
import SubHeadline from '@/components/ui/text/subheadline'
import LeadText from '@/components/ui/text/lead-text'
import Spunq from '@/components/spunq-tags/spunq'
import CaptionImage from '@/components/ui/image/caption-image'

export default {
  components: {
    SubHeadline,
    LeadText,
    Spunq,
    CaptionImage,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    headlineOffset() {
      if (this.section.attributes.level === 0) {
        return this.section.attributes.level + 2
      } else {
        return this.section.attributes.level + 1
      }
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.section-default--testimonial {
  @at-root .section-default#{&} {
    // compiles to .section-default.section-default--testimonial
    margin-top: 0 !important;
    padding: 0 !important;
    width: calc(100% - 40px);
  }

  .testimonial-content {
    background-color: $color-white;
    margin: 20px;
    padding: 40px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.16);
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (min-width: $screen-width-767) {
    }

    .testimonial-person {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &__image {
        border-radius: 100%;
        overflow: hidden;
        width: 60px;
        height: 60px;
        margin: 0 20px 0 0;
      }

      &__data {
        .name {
          font-size: 18px;
          line-height: 115%;
        }

        .role {
          font-size: 16px;
          color: $color-text-gray;
          line-height: 115%;
        }
      }

      &-head {
      }
    }

    .article-leadtext {
      text-align: left;
    }

    &__bodytext {
      p {
        font-size: 20px;
        color: $color-text-light;
      }
    }
  }
}
</style>
