<template>
  <section :id="`section-${section.id}`" class="section-default section-default--product">
    <header class="section-header">
      <SubHeadline v-if="section.attributes.values.title && headlineOffset === 2" :tag="`h${headlineOffset}`">
        {{ section.attributes.values.title }}
      </SubHeadline>
      <LeadText v-if="section.attributes.values.leadText && section.attributes.level === 1" class="article-leadtext">
        {{ section.attributes.values.leadText }}
      </LeadText>
    </header>
    <div class="content" :class="contentModifierClasses">
      <div v-if="section.attributes.values.bodyText || section.attributes.values.leadText" class="lead">
        <SubHeadline v-if="section.attributes.values.title && headlineOffset === 3" :tag="`h${headlineOffset}`">
          {{ section.attributes.values.title }}
        </SubHeadline>
        <LeadText
          v-if="section.attributes.values.leadText && section.attributes.level !== 1"
          class="standard-product-leadtext"
        >
          {{ section.attributes.values.leadText }}
        </LeadText>
        <Spunq
          v-if="section.attributes.values.bodyText"
          :text="section.attributes.values.bodyText"
          :inline-objects="section.relationships.inlineObjects"
          :inline-images="section.attributes.inlineImages"
          :headline-offset="headlineOffset"
          class="section-default__bodytext"
        />
      </div>
      <div v-if="section.attributes.images.length > 0" class="visual">
        <CaptionImage
          v-for="(image, index) in section.attributes.images"
          :key="index"
          :image="image"
          :width="980"
          :height="540"
          :mobile-width="767"
          :mobile-height="767"
          lazy
        >
          <span v-if="image.attributes.copyright" class="caption-image__copyright">
            &copy; {{ image.attributes.copyright }}
          </span>
        </CaptionImage>
      </div>
      <div v-else-if="section.attributes.values.embedLink" class="visual embedlink--product">
        <div class="emdbedlink">
          <!-- eslint-disable vue/no-v-html -->
          <div class="responsive embedded-iframe" v-html="section.attributes.values.embedLink" />
          <!-- eslint-enable vue/no-v-html -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SubHeadline from '@/components/ui/text/subheadline'
import LeadText from '@/components/ui/text/lead-text'
import Spunq from '@/components/spunq-tags/spunq'
import CaptionImage from '@/components/ui/image/caption-image'

export default {
  components: {
    LeadText,
    SubHeadline,
    Spunq,
    CaptionImage,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  computed: {
    headlineOffset() {
      const { level } = this.section.attributes
      return level === 0 ? level + 2 : level + 1
    },
    contentModifierClasses() {
      return `content--image-${this.section.attributes.values.imagePosition}`
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.buttonLink {
  margin-top: 15px;
}

.section-default--product {
  @at-root .section-default#{&} {
    // compiles to .section-default.section-default--product

    .content {
      display: flex;

      .lead {
        @media (min-width: $screen-width-767) {
          max-width: 50%;
        }
        .section-default__bodytext {
          max-width: 980px;
        }
      }

      &--image-left {
        flex-direction: column-reverse;

        @media (min-width: $screen-width-767) {
          flex-direction: row-reverse;
          align-items: flex-start;
        }

        .visual {
          flex: 1;

          @media (min-width: $screen-width-1023) {
            margin-right: 40px;
          }
        }
      }

      &--image-right {
        flex-direction: column-reverse;

        @media (min-width: $screen-width-767) {
          flex-direction: row;
          align-items: flex-start;
        }

        .visual {
          flex: 1;

          @media (min-width: $screen-width-1023) {
            margin-left: 40px;
          }
        }
      }

      &--image-top {
        flex-direction: column-reverse;

        .lead {
          @media (min-width: $screen-width-767) {
            max-width: 100%;
          }
        }

        .visual {
          @media (min-width: $screen-width-767) {
            margin-bottom: 10px;
          }
        }
      }

      &--image-bottom {
        flex-direction: column;

        .lead {
          @media (min-width: $screen-width-767) {
            max-width: 100%;
          }
        }

        .visual {
          @media (min-width: $screen-width-767) {
            margin-top: 10px;
          }
        }
      }
    }

    .embedlink--product {
      max-width: 980px;
      width: 100%;
      margin: 0 auto 10px auto;
    }
  }
}
</style>
