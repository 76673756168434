<template>
  <div class="print" @click="printPage">
    <i>
      <IconPrint />
    </i>
    Artikel drucken
  </div>
</template>

<script>
import IconPrint from '@/components/ui/icons/icon-print.svg'

export default {
  components: {
    IconPrint,
  },
  methods: {
    printPage() {
      window.print()
    },
  },
}
</script>

<style lang="scss">
@import '../../css/import/vars';

.print {
  display: none;

  @media all and (min-width: $screen-width-767) {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  i {
    padding-right: 10px;

    svg {
      display: block;
    }
  }
}
</style>
