<template>
  <section class="section-default section-default--header">
    <div v-if="section.attributes.values.backgroundImage" class="background">
      <SpunqImage
        v-for="(image, index) in section.attributes.images.slice(0, 1)"
        :key="index"
        :image="image"
        :width="1440"
        :height="600"
        :mobile-width="600"
        :mobile-height="400"
        mode="crop"
      />
    </div>
    <div class="lead">
      <SubHeadline v-if="section.attributes.values.title" :tag="`h${headlineOffset}`">
        {{ section.attributes.values.title }}
      </SubHeadline>
      <Spunq
        v-if="section.attributes.values.bodyText"
        :text="section.attributes.values.bodyText"
        :inline-objects="section.relationships.inlineObjects"
        :inline-images="section.attributes.inlineImages"
        :headline-offset="headlineOffset"
      />
      <a
        v-if="section.attributes.values.buttonText || section.attributes.values.buttonLink"
        :href="`${buttonLink}`"
        class="button"
      >
        {{ section.attributes.values.buttonText }}
      </a>
    </div>
    <div v-if="section.attributes.images.length > 0 || section.attributes.values.embedLink" class="visual">
      <div v-if="section.attributes.values.embedLink" class="emdbedlink">
        <!-- eslint-disable vue/no-v-html -->
        <div class="responsive embedded-iframe" v-html="embeddedLink" />
        <!-- eslint-enable vue/no-v-html -->
      </div>

      <!-- if we have no embedLink and backgroundImage is not set, take the first image -->
      <CaptionImage
        v-for="(image, index) in section.attributes.images.slice(0, 1)"
        v-else-if="!section.attributes.values.embedLink && !section.attributes.values.backgroundImage"
        :key="index"
        :image="image"
        :width="980"
        :height="540"
        :mobile-width="375"
        :mobile-height="320"
        lazy
      />
      <!-- if backgroundImage is set take the first image as background and the second as inline-image -->
      <CaptionImage
        v-for="(image, index) in section.attributes.images.slice(1, 2)"
        v-else
        :key="index"
        :image="image"
        :width="980"
        :height="540"
        :mobile-width="375"
        :mobile-height="320"
        lazy
      />
    </div>
    <slot />
  </section>
</template>

<script>
import SpunqImage from '../../ui/image/spunq-image'
import SubHeadline from '@/components/ui/text/subheadline'
import Spunq from '@/components/spunq-tags/spunq'
import CaptionImage from '@/components/ui/image/caption-image'

export default {
  components: {
    SubHeadline,
    SpunqImage,
    Spunq,
    CaptionImage,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isMobile: false,
    }
  },
  computed: {
    // warning: this section has different 'headlineOffset' logic than other sections!
    headlineOffset() {
      const { level } = this.section.attributes
      return level < 2 ? 1 : level
    },
    backgroundImage() {
      if (!this.section.attributes.values.backgroundImage) {
        return ''
      }
      const baseURL = this.$config.imageServiceBaseUrls
      const fileName = encodeURIComponent(this.section.attributes.images[0].attributes.fileName)
      return `${baseURL}/${this.section.attributes.images[0].attributes.fileId}-600x400c-${fileName}`
    },
    buttonLink() {
      return this.section.attributes.values.buttonLink
    },
    embeddedLink() {
      if (this.section.attributes.values.embedLink !== 'undefined') {
        return this.section.attributes.values.embedLink
      }
      return null
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.section-default--header {
  width: 100%;
  max-width: $content-max-width;

  @at-root .section-default#{&} {
    // compiles to .section-default.section-default--header
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    padding: 0 20px;

    @media (min-width: $screen-width-767) {
      position: static;
      min-height: 520px;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .background {
    z-index: -1;
    position: absolute;
    bottom: -30px;

    @media (min-width: $screen-width-767) {
      margin-left: -165px;
      bottom: 80px;
    }
  }

  .lead {
    margin: 0 0 20px 0;

    @media (min-width: $screen-width-767) {
      padding: 1rem 0;
      width: 100%;
      max-width: 500px;
      margin: 0 20px 0 0;
      overflow: hidden;
    }

    .subheadline {
      text-align: center;
      font-size: 1.5rem;
      margin-bottom: 1rem;

      @media (min-width: $screen-width-767) {
        text-align: left;
      }
    }

    p {
      padding: 0;
      text-align: center;

      @media (min-width: $screen-width-767) {
        color: $color-headline;
        text-align: left;
      }
    }

    .button {
      height: auto;
      font-size: 18px;
      padding: 5px 10px;
      margin: 2rem auto 0 auto;
      text-align: center;

      @media (min-width: $screen-width-767) {
        position: relative;
        margin: 2rem 0 1rem 0;
      }
    }
  }

  .visual {
    margin: 0 auto;
    width: 100%;
    max-width: 530px;
    padding: 30px 0;

    @media (min-width: $screen-width-767) {
      padding: 0;
      margin: auto 0;
    }

    .emdbedlink {
      max-width: 530px;
      margin: 0 auto;
    }
  }
}
</style>
