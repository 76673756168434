<template>
  <span class="date"> Aktualisiert am {{ date | formatDate }} </span>
</template>

<script>
export default {
  props: {
    date: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '../../css/import/vars';

.date {
  font-size: 16px;
  line-height: 120%;
  color: $color-text-light;
  margin-bottom: 1rem;
  display: block;
}
</style>
