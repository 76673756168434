<template>
  <div v-if="image.attributes.copyright" class="image-copyright">&copy; {{ image.attributes.copyright }}</div>
</template>

<script>
export default {
  props: {
    image: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars.scss';

.image-copyright {
  position: absolute;
  right: 10px;
  bottom: 5px;
  font-size: 0.8rem;
  color: $color-white;
}
</style>
