<template>
  <a :href="formattedUrl" :title="title" target="_blank" class="file-download">
    <i v-if="type.indexOf('pdf') !== -1">
      <IconPdf />
    </i>
    <i v-else>
      <IconFile />
    </i>
    <slot>
      {{ title }}
    </slot>
  </a>
</template>

<script>
import IconFile from '@/components/ui/icons/icon-file.svg'
import IconPdf from '@/components/ui/icons/icon-pdf.svg'

export default {
  components: {
    IconFile,
    IconPdf,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    baseUrl() {
      const fileServiceBaseUrls = this.$config.fileServiceBaseUrls
      return fileServiceBaseUrls[this.id % fileServiceBaseUrls.length]
    },
    formattedUrl() {
      if (this.url) {
        return this.url
      }
      const fileName = this.$slugify(this.title)
      return `${this.baseUrl}/${this.id}-${fileName}`
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.file-download {
  color: $color-text;

  i {
    margin-right: 0.5rem;
    display: inline-block;
    vertical-align: middle;
  }
}
</style>
