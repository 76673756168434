<template>
  <nav class="productpage-navigation" :class="navigationModifiers">
    <button
      :id="swiperButtonPrevId"
      class="productpage-navigation__btn productpage-navigation__btn--prev inverted-colors"
      aria-label="Vorherige Produkte"
    >
      &lt;
    </button>
    <button
      :id="swiperButtonNextId"
      class="productpage-navigation__btn productpage-navigation__btn--next inverted-colors"
      aria-label="Nächste Produkte"
    >
      &gt;
    </button>
    <div v-swiper:productNavigationSwiper="swiperOptions">
      <ul class="swiper-wrapper">
        <li class="swiper-slide">
          <a href="#top" class="productpage-navigation__link">Top</a>
        </li>
        <template v-for="section in structuredSections">
          <li v-if="isPartOfNavigation(section)" :key="section.id" class="swiper-slide">
            <a
              :href="`#section-${section.id}`"
              :nav-data="`section-${section.id}`"
              class="productpage-navigation__link"
            >
              {{ section.attributes.values.title }}
            </a>
          </li>
        </template>
      </ul>
    </div>
  </nav>
</template>

<script>
import buildSectionsStructure from '@/components/article/buildSectionsStructure'

export default {
  name: 'SectionsLoopProductpages',
  props: {
    sections: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    navigationModifiers: null,
  }),
  computed: {
    structuredSections() {
      return buildSectionsStructure(this.sections)
    },
    swiperButtonPrevId() {
      return 'swiper-button-prev-product-navigation'
    },
    swiperButtonNextId() {
      return 'swiper-button-next-product-navigation'
    },
    swiperOptions() {
      return {
        navigation: {
          prevEl: `#${this.swiperButtonPrevId}`,
          nextEl: `#${this.swiperButtonNextId}`,
        },
        slidesPerView: 'auto',
        grabCursor: true,
        mousewheel: true,
        centerInsufficientSlides: true,
        slideToClickedSlide: true,
        freeMode: true,
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
    if (!window.IntersectionObserver) {
      return
    }
    const sections = document.querySelectorAll('.l1')
    const navElements = document.querySelectorAll('.productpage-navigation__link')
    const observerOptions = {
      root: null,
      rootMargin: '-30% 0px -69% 0px',
      threshold: 0,
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          navElements.forEach((navElement, index) => {
            if (entry.target.getAttribute('observe-data') === navElement.getAttribute('nav-data')) {
              navElement.classList.add('active')
              this.slideTo(index)
            } else {
              navElement.classList.remove('active')
            }
          })
        }
        // no navigation item is active -> scroll navigation bar to the start
        if (!Array.from(navElements).find((element) => element.classList.contains('active'))) {
          this.slideTo(0)
        }
      })
    }, observerOptions)

    sections.forEach((section) => {
      observer.observe(section)
    })
  },
  methods: {
    sanitizeSectionTemplate(template) {
      let name = template.replace(/cms\.apoverlag\.article\._sections\./, '')
      name = name.replace(/-([a-z])/g, function (g) {
        return g[1].toUpperCase()
      })
      return name + 'Section'
    },
    isPartOfNavigation(section) {
      return (
        section.attributes.level === 1 &&
        section.attributes.values.title &&
        parseInt(section.attributes.values.hideNav) !== 1
      )
    },
    onScroll() {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      this.navigationModifiers = currentScrollPosition > 650 ? 'productpage-navigation--border-bottom' : null
    },
    slideTo(index) {
      if (this.productNavigationSwiper) {
        this.productNavigationSwiper.slideTo(index)
      }
    },
  },
}
</script>

<style lang="scss">
@import '../../css/import/vars';

.productpage-navigation {
  display: none;

  @media screen and (min-width: $screen-width-767) {
    display: block;
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: $color-white;
    max-width: $content-max-width;
    width: 100%;
    margin-bottom: 10px;
    padding: 0 45px;
  }

  @media screen and (min-width: $screen-width-1110) {
    padding: 0 30px;
  }

  &--border-bottom {
    @media screen and (min-width: $screen-width-767) {
      border-bottom: 1px solid $color-light-gray-border;
    }
  }

  &::before {
    @media screen and (min-width: $screen-width-767) {
      content: '';
      position: absolute;
      top: 0;
      left: 45px;
      width: 10px;
      height: 79px;
      background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
      z-index: 10;
    }

    @media screen and (min-width: $screen-width-1110) {
      left: 30px;
    }
  }

  &::after {
    @media screen and (min-width: $screen-width-767) {
      content: '';
      position: absolute;
      top: 0;
      right: 45px;
      width: 10px;
      height: 79px;
      background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
      z-index: 10;
    }

    @media screen and (min-width: $screen-width-1110) {
      right: 30px;
    }
  }

  &__btn {
    display: none;
    background-color: unset !important;
    color: $color-red;

    @media screen and (min-width: $screen-width-767) {
      display: block;
      position: absolute;
      box-sizing: content-box;
      font-size: 20px;
      padding: 18px 15px;

      svg {
        position: relative;
        top: 3px;
      }
    }

    &--prev {
      left: 0;

      @media screen and (min-width: $screen-width-1110) {
        left: -15px;
      }

      svg {
        transform: rotate(180deg);
        left: -1px;
      }
    }

    &--next {
      right: 0;

      @media screen and (min-width: $screen-width-1110) {
        right: -15px;
      }

      svg {
        left: 1px;
      }
    }
  }

  &__link {
    border-bottom: 3px solid $color-white;
    transition: 0.3s;

    &.active {
      border-bottom: 3px solid $color-red;
    }
  }

  .swiper-container {
    display: flex;
    width: 100%;
    justify-content: center;

    &:active {
      .productpage-navigation__link:hover {
        cursor: -webkit-grabbing;
        cursor: -moz-grabbing;
        cursor: grabbing;
      }
    }

    ul.swiper-wrapper {
      height: 80px;
      display: flex;
      margin: 0;
      padding: 0;

      li.swiper-slide {
        width: auto;
        display: flex;
        align-items: center;
        padding: 0 10px 0 10px;
        margin: 0;

        &:last-child {
          padding-right: 15px;
        }

        &:before {
          display: none;
        }
      }
    }
  }

  .swiper-button-disabled {
    @media screen and (min-width: $screen-width-1110) {
      display: none;
    }
  }
}
</style>
