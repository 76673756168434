<template>
  <section :id="`section-${section.id}`" class="section-default">
    <SubHeadline v-if="section.attributes.values.title" :tag="`h${headlineOffset}`">
      {{ section.attributes.values.title }}
    </SubHeadline>
    <div v-if="section.attributes.values.embedLink" class="visual news-embedlink">
      <div class="emdbedlink">
        <!-- eslint-disable vue/no-v-html -->
        <div class="responsive embedded-iframe" v-html="embeddedLink" />
        <!-- eslint-enable vue/no-v-html -->
      </div>
    </div>
    <CaptionImage
      v-for="(image, index) in section.attributes.images"
      :key="index"
      :image="image"
      :width="980"
      :height="540"
      :mobile-width="375"
      :mobile-height="320"
      lazy
    >
      {{ image.attributes.description }}
      <span v-if="image.attributes.copyright" class="caption-image__copyright">
        &copy; {{ image.attributes.copyright }}
      </span>
    </CaptionImage>
    <LeadText v-if="section.attributes.values.leadText" class="article-leadtext">
      {{ section.attributes.values.leadText }}
    </LeadText>
    <Spunq
      v-if="section.attributes.values.bodyText"
      :text="section.attributes.values.bodyText"
      :inline-objects="section.relationships.inlineObjects"
      :inline-images="section.attributes.inlineImages"
      :headline-offset="headlineOffset"
    />
    <div v-if="section.relationships.files.length > 0" class="section-default__files">
      <FileDownload
        v-for="file in section.relationships.files"
        :id="file.attributes.fileId"
        :key="file.id"
        :type="file.attributes.mimeType"
        :title="file.attributes.title"
      />
    </div>
    <slot />
  </section>
</template>

<script>
import SubHeadline from '@/components/ui/text/subheadline'
import LeadText from '@/components/ui/text/lead-text'
import Spunq from '@/components/spunq-tags/spunq'
import CaptionImage from '@/components/ui/image/caption-image'
import FileDownload from '@/components/ui/file/file-download'

export default {
  components: {
    SubHeadline,
    LeadText,
    Spunq,
    CaptionImage,
    FileDownload,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    headlineOffset() {
      if (this.section.attributes.level === 0) {
        return this.section.attributes.level + 2
      } else {
        return this.section.attributes.level + 1
      }
    },
    embeddedLink() {
      if (this.section.attributes.values.embedLink !== 'undefined') {
        return this.section.attributes.values.embedLink
      }
      return null
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.section-default {
  margin-bottom: 2rem;
  width: 100%;
  max-width: $content-max-width;
  margin: 0 auto;

  &__files {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
  }
  .news-embedlink {
    margin-bottom: 2rem;
  }
}
</style>
