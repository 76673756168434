<template>
  <div class="sections">
    <div v-if="parent === 'cms.apoverlag.article._sections.testimonials'" class="section-slider">
      <div :class="{ 'section-slider__gradient--hide': slider.isEnd }" class="section-slider__gradient" />
      <button id="slider-button-prev" class="section-slider__button section-slider__button--prev">
        <IconSliderArrow />
      </button>
      <button id="slider-button-next" class="section-slider__button section-slider__button--next">
        <IconSliderArrow />
      </button>
      <div v-swiper:mySwiper="swiperOptions" @reachEnd="reachedEnd" @fromEdge="slideFromEdge">
        <div class="swiper-wrapper">
          <template v-for="(section, index) in structuredSections">
            <div
              :is="sanitizeSectionTemplate(section.attributes.template)"
              :key="index"
              :section="section"
              class="swiper-slide"
            />
          </template>
        </div>
      </div>
    </div>
    <template v-else>
      <template v-for="(section, index) in structuredSections">
        <div
          :is="sanitizeSectionTemplate(section.attributes.template)"
          :key="index"
          :observe-data="`section-${section.id}`"
          :section="section"
          :class="[{ l1: section.attributes.level === 1 }, `level${section.attributes.level}`]"
        >
          <SectionsLoopProductpages
            v-if="section.children && section.children.length"
            :parent="section.attributes.template"
            :sections="section.children"
            :firstloop="false"
            :class="[
              { 'sections-loop-column': sanitizeSectionTemplate(section.attributes.template) === 'columnSection' },
            ]"
          />
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import PageHeader from '@/components/header/default'
import buildSectionsStructure from '@/components/article/buildSectionsStructure'
import DefaultSection from '@/components/article/sections/default'
import DefaultProductSection from '@/components/article/sections/default-product'
import ColumnSection from '@/components/article/sections/column'
import HeaderSection from '@/components/article/sections/header'
import OfferPlainSection from '@/components/article/sections/offer-plain'
import OfferSection from '@/components/article/sections/offer'
import OffersSection from '@/components/article/sections/offers'
import OverviewSection from '@/components/article/sections/overview'
import TestimonialSection from '@/components/article/sections/testimonial'
import TestimonialsSection from '@/components/article/sections/testimonials'
import DownloadsSection from '@/components/article/sections/downloads'
import ContactSection from '@/components/article/sections/contact'
import GtvVideoloopSection from '@/components/article/sections/gtv-videoloop'
import PartnersSection from '@/components/article/sections/partners'
import PartnerSection from '@/components/article/sections/partner'
import ContactPartnerSection from '@/components/article/sections/contact-partner'
import FullWidthSection from '@/components/article/sections/full-width-image'

import IconSliderArrow from '@/components/ui/icons/icon-slider-arrow.svg'

export default {
  name: 'SectionsLoopProductpages',
  components: {
    PageHeader,
    DefaultSection,
    DefaultProductSection,
    ColumnSection,
    HeaderSection,
    OfferPlainSection,
    OfferSection,
    OffersSection,
    OverviewSection,
    TestimonialSection,
    TestimonialsSection,
    IconSliderArrow,
    DownloadsSection,
    ContactSection,
    GtvVideoloopSection,
    PartnersSection,
    PartnerSection,
    ContactPartnerSection,
    FullWidthSection,
  },
  props: {
    parent: {
      type: String,
      required: false,
      default: 'default',
    },
    sections: {
      type: Array,
      required: true,
    },
    firstloop: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      slider: {
        isEnd: false,
      },
      swiperOptions: {
        navigation: {
          nextEl: '#slider-button-next',
          prevEl: '#slider-button-prev',
        },
        spaceBetween: 0,
        slidesPerView: 1.2,
        a11y: true,
        keyboardControl: false,
        centeredSlides: false,
        allowTouchMove: true,
        simulateTouch: true,
        preloadImages: false,
        lazy: true,
        breakpoints: {
          767: {
            slidesPerView: 1.5,
          },
          1024: {
            slidesPerView: 2.5,
          },
          1150: {
            slidesPerView: 3,
          },
        },
      },
    }
  },
  computed: {
    structuredSections() {
      return this.buildSectionsStructure(this.sections)
    },
  },
  methods: {
    sanitizeSectionTemplate(template) {
      let name = template.replace(/cms\.apoverlag\.article\._sections\./, '')
      name = name.replace(/-([a-z])/g, function (g) {
        return g[1].toUpperCase()
      })
      return name + 'Section'
    },
    buildSectionsStructure,
    reachedEnd() {
      this.slider.isEnd = true
    },
    slideFromEdge() {
      this.slider.isEnd = false
    },
  },
}
</script>

<style lang="scss">
@import '../../css/import/vars';

.product-article .section-slider__button {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background: none;
  margin: auto;
  display: none;
  background: none !important;

  @media screen and (min-width: $screen-width-767) {
    display: block;
  }

  svg {
    -webkit-filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.2));
  }

  &.swiper-button-disabled {
    display: none;
  }

  &--prev {
    left: 0;

    @media screen and (min-width: 1151px) {
      left: -30px;
    }

    svg {
      transform: rotate(180deg);
      -webkit-filter: drop-shadow(1px -2px 2px rgba(0, 0, 0, 0.2));
      filter: drop-shadow(1px -2px 2px rgba(0, 0, 0, 0.2));
    }
  }

  &--next {
    left: auto;
    right: 0;

    @media screen and (min-width: 1151px) {
      right: -30px;
    }
  }
}

.section-slider {
  position: relative;
  max-width: 1150px;
  width: 100%;
  margin: 0 auto;

  &__gradient {
    background: -moz-linear-gradient(
      left,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.01) 1%,
      rgba(0, 0, 0, 0.6) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      left,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.01) 1%,
      rgba(0, 0, 0, 0.6) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.01) 1%,
      rgba(0, 0, 0, 0.6) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#99000000',GradientType=1 ); /* IE6-9 */
    position: absolute;
    top: 20px;
    bottom: 20px;
    right: 0;
    z-index: 2;
    width: 50px;
    opacity: 0.15;
    transition-duration: 0.1s;

    @media screen and (min-width: $screen-width-767) {
      width: 130px;
    }

    @media screen and (min-width: 1151px) {
      display: none;
    }

    &--hide {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }
  }

  .swiper-slide {
    align-self: stretch;
  }

  .swiper-wrapper {
    .section-default--testimonial {
      max-width: 500px;
      height: auto;
    }
  }
}
</style>
