<template>
  <section :id="`section-${section.id}`" class="section-default section-default--offer">
    <div class="single-offer">
      <header class="single-offer__header">
        <SubHeadline v-if="section.attributes.values.title" :tag="`h${headlineOffset}`">
          {{ section.attributes.values.title }}
        </SubHeadline>
        <LeadText v-if="section.attributes.values.leadText" class="article-leadtext">
          {{ section.attributes.values.leadText }}
        </LeadText>
      </header>
      <div class="single-offer__body">
        <Spunq
          v-if="section.attributes.values.bodyText"
          :text="section.attributes.values.bodyText"
          :headline-offset="headlineOffset"
          class="single-offer__bodytext"
        />
      </div>
      <slot />
    </div>
  </section>
</template>

<script>
import SubHeadline from '@/components/ui/text/subheadline'
import LeadText from '@/components/ui/text/lead-text'
import Spunq from '@/components/spunq-tags/spunq'

export default {
  components: {
    SubHeadline,
    LeadText,
    Spunq,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    showCta: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    headlineOffset() {
      if (this.section.attributes.level === 0) {
        return this.section.attributes.level + 2
      } else {
        return this.section.attributes.level + 1
      }
    },
  },
}
</script>
