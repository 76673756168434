<template>
  <section :id="`section-${section.id}`" class="section-default section-default--full-width-image">
    <header class="section-header">
      <SubHeadline v-if="section.attributes.values.title" :tag="`h${headlineOffset}`">
        {{ section.attributes.values.title }}
      </SubHeadline>
      <LeadText v-if="section.attributes.values.leadText" class="article-leadtext">
        {{ section.attributes.values.leadText }}
      </LeadText>
    </header>
    <CaptionImage
      v-if="section.attributes.images.length"
      :image="section.attributes.images[0]"
      :width="1110"
      :height="1110"
      :mobile-width="767"
      :mobile-height="767"
      lazy
      class="full-width-image"
    />
  </section>
</template>

<script>
import SubHeadline from '@/components/ui/text/subheadline'
import LeadText from '@/components/ui/text/lead-text'
import CaptionImage from '@/components/ui/image/caption-image'

export default {
  components: {
    SubHeadline,
    LeadText,
    CaptionImage,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  computed: {
    headlineOffset() {
      const { level } = this.section.attributes
      return level === 0 ? level + 2 : level + 1
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.section-default--full-width-image {
  @at-root .section-default#{&} {
    // compiles to .section-default.section-default--full-width-image

    .full-width-image {
      display: block;
    }
  }
}
</style>
